import { useState } from 'react';
import './userProfile.css'

const UserProfile = () => {
  // Sample user data (you can replace it with actual user data from your authentication system)
  const user = {
    name: 'Michael',
    role: 'Agent',
    // Add more user details as needed
  };

  const userName = localStorage.getItem('userName')
  const userRole = localStorage.getItem('userRole')
  const userLocation = localStorage.getItem('userLocation')
  const userTimeZone = localStorage.getItem('userTimeZone')
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
 

  const [selectedTimeZone, setSelectedTimeZone] = useState(user.timeZone || ''); // Set the initial value to the user's time zone

  const handleSave = () => {
    // Implement save logic here (e.g., make an API call to update user profile)
    console.log('Saving profile changes...');
  };

  // console.log(userRole)

  return (
    <div className="user-profile-container">
      <h2 className='title'>User Profile</h2>
      <div className="user-details">
        <p>
          <strong>Name:</strong> {userName}
        </p>
        <p>
          <strong>Role:</strong> {userRole && userRole.includes("SUPERADMIN") ? 'SUPERADMIN' : (userRole && userRole)}
        </p>
        <p>
          <strong>Location:</strong> {userLocation}
        </p>
        <p>
          <strong>Time Zone:</strong> {userTimeZone}
        </p>
        <p>
          <strong>Box Max:</strong> {currentUser.BoxMax}
        </p>
        <p>
          <strong>Str Max:</strong> {currentUser.StrMax}
        </p>
        <p>
          <strong>AM_Lock:</strong> {currentUser.AM_Lock}
        </p>
        <p>
          <strong>AM_Display:</strong> {currentUser.AM_Display}
        </p>
        <p>
          <strong>PM_Lock:</strong> {currentUser.PM_Lock}
        </p>
        <p>
          <strong>PM_Display:</strong> {currentUser.PM_Display}
        </p>
      </div>
      {/* <div className="profile-form">
        <label>
          Time Zone
          <select
            value={selectedTimeZone}
            onChange={(e) => setSelectedTimeZone(e.target.value)}
          >
            <option value="GMT">GMT</option>
            <option value="EST">EST</option>
            <option value="CST">CST</option>
          </select>
        </label>
        <button type="button" className='save-button' onClick={handleSave}>
          Save
        </button>
      </div> */}
    </div>
  );
};

export default UserProfile;
