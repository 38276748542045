import { useNavigate } from 'react-router-dom';
import './signup.css'
import { useEffect, useState } from 'react';
import Loader from '../../Components/Loader';

import logoWhite from './../../Assets/Images/logo-white.png'

const Signup = () => {

  const userToken = localStorage.getItem('userToken')

  // const BASE_URL = 'http://localhost:5000'
  const BASE_URL = 'https://dev.soso123.me'
  const navigate = useNavigate();
  // const [isActive, setIsActive] = useState(true);
  // const [date, setDate] = useState(true);

  const [isLoading, setIsLoading] = useState(false)

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [timezone, setTimezone] = useState('PST');
  const [location, setLocation] = useState('CA');
  const [boxMax, setBoxMax] = useState(200);
  const [strMax, setStrMax] = useState(200);
  const [amDisplay, setAMDisplay] = useState('1:00 PM');
  const [amLock, setAMLock] = useState('12:50');
  const [pmDisplay, setPMDisplay] = useState('6:30 PM');
  const [pmLock, setPMLock] = useState('18:20');
  const [notes, setNotes] = useState('');
  const [selectedRole, setSelectedRole] = useState("AGENT");

  const userName = localStorage.getItem('userName');
  const userRole = localStorage.getItem('userRole');

  const isFieldDisabled = userRole && userRole.includes('SUPERADMIN') ? false : true;

  const convertTimeToDateTime = (timeString) => {
    const currentDate = new Date(); // Get the current date
    const [hours, minutes] = timeString.split(':'); // Extract hours and minutes
    currentDate.setHours(hours, minutes, 0, 0); // Set the time in the current date
    return currentDate.toISOString(); // Convert to ISO string format
  };

  useEffect(() => {
    // This block of code will run when the component mounts (similar to componentDidMount)
    
    setNotes(`Created by ${userName}`)
    // Clean-up code can be placed here if needed

    return () => {
      // Clean-up code if needed (runs when the component unmounts)
    };
  }, []);

  const createUser = async () => {

    if (username === '' || password === '' || timezone === '' || location === '' || boxMax === '' ||
     strMax === '' || amDisplay === '' || amLock === '' || pmDisplay === '' || pmLock === '' || notes === '') {
      alert('Please Enter Required details in All Fields')
      return
    }

    setTimeout(() => {
      setIsLoading(true);
    }, 10);

    const response = await fetch(`${BASE_URL}/admin/createUser`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
        timezone: timezone,
        location: location,
        boxMax: parseInt(boxMax),
        strMax: parseInt(strMax),
        amDisplay: amDisplay,
        amLock: amLock + ':00',
        pmDisplay: pmDisplay,
        pmLock: pmLock + ':00',
        notes: notes,
        role: selectedRole,
      }),
    });

    const json = await response.json();

    if (json.success === false) {
      alert(json.message);
      setIsLoading(false)
    }

    if (json.success === true) {
      alert(json.message);
      setIsLoading(false)
    }

    setIsLoading(false)

    // console.log(json);
  }

  return (
    <>
      <div className='signup-container'>
        {/* <h2 className='logo'><img src={logoWhite} alt="SoSo" width={160} /></h2> */}
        <div className='card'>
          <h2>Create Account</h2>
          <div className="form-item">
            <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
            <label htmlFor="username">Username</label>
          </div>

          <div className="form-item">
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            <label htmlFor="password">Password</label>
          </div>

          <div className="form-item">
            <input type="text" id="location" value={location} onChange={(e) => setLocation(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="location">Location</label>
          </div>

          <div className="form-item">
            <input type="text" id="timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="timezone">Time Zone</label>
          </div>

          <div className="form-item">
            <input type="text" name='BoxMax' id="boxMax" value={boxMax} onChange={(e) => setBoxMax(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="boxMax">Box Max</label>
          </div>
          <div className="form-item">
            <input type="text" name='StrMax' id="strMax" value={strMax} onChange={(e) => setStrMax(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="strMax">Str Max</label>
          </div>
          <div className="form-item">
            <input type="text" name='AM_Display' id="am_display" value={amDisplay} onChange={(e) => setAMDisplay(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="am_display">AM_Display</label>
          </div>
          <div className="form-item">
            <input type="time" name='AM_Lock' id="am_lock" value={amLock} onChange={(e) => setAMLock(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="am_lock">AM_Lock</label>
          </div>
          <div className="form-item">
            <input type="text" name='PM_Display' id="PM_Display" value={pmDisplay} onChange={(e) => setPMDisplay(e.target.value)} required disabled={isFieldDisabled}/>
            <label htmlFor="PM_Display">PM_Display</label>
          </div>
          <div className="form-item">
            <input type="time" name='PM_Lock' id="PM_Lock" value={pmLock} onChange={(e) => setPMLock(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="PM_Lock">PM_Lock</label>
          </div>
          <div className="form-item">
            <input type="text" name='Notes' id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} required disabled={isFieldDisabled} />
            <label htmlFor="notes">Notes</label>
          </div>

          {userRole && userRole.includes("SUPERADMIN") ? (
            <div className="profile-form">
              <label>
                Select Role
                <select
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="AGENT">AGENT</option>
                  <option value="ADMIN">ADMIN</option>
                </select>
              </label>
            </div>) : <></>}

          <button className='login-btn' onClick={createUser}>
            {isLoading ? <Loader size="20px" /> : "Create Account"}
          </button>
          {/* <p className='register-link'>
            Already have an account? <Link to='/'>Login Now</Link>
          </p> */}
        </div>
      </div>
    </>
  )
}

export default Signup;