// ChangePassword.js
import React, { useEffect, useState } from 'react';
import './changePassword.css';

import eyeOpenImg from './../../Assets/Images/ChangePassword/eye-open.png'
import eyeClosedImg from './../../Assets/Images/ChangePassword/eye-closed.png'
import Loader from '../../Components/Loader';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';

const ChangePassword = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const userName = localStorage.getItem('userName');
    const userToken = localStorage.getItem('userToken');
    const userRole = localStorage.getItem('userRole');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const isFieldDisabled = false;

    // const BASE_URL = 'http://localhost:5000'
    const BASE_URL = 'https://dev.soso123.me'

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isRoleLoading, setIsRoleLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState({
        label: "Myself", value: {
            AM_Display: null,
            AM_Lock: null,
            AccessLevel: 1,
            Active: true,
            BoxMax: "200",
            Location: "CA",
            Notes: null,
            PM_Display: null,
            PM_Lock: null,
            StrMax: "200",
            TimeZone: "PST",
            aName: "AdminTest"
        }
    })
    const [selectedUserLabel, setSelectedUserLabel] = useState({ label: "Select", value: userName })
    const [users, setUsers] = useState([])

    const [selectedRole, setSelectedRole] = useState("AGENT")

    useEffect(() => {
        
        getUsers();
    }, []);
    
    const getUsers = async () => {
        try {
            const response = await fetch(`${BASE_URL}/users/getUsers`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'content-type': 'application/json'
                }
            });
            const json = await response.json();

            if (json.success) {
                const sortedData = await json.data.sort((a, b) => {
                    const dateA = new Date(a.cDate);
                    const dateB = new Date(b.cDate);

                    // Compare the dates in descending order
                    return dateB - dateA;
                });

                setUsers(sortedData);
                if (id !== 'NaN') {
                    setSelectedUser(sortedData[id])
                    setSelectedUserLabel({ label: sortedData[id].aName })
                }
            }

            if (json.message === 'Token is not valid.') {
                alert("Token is not valid. Please Login Again.");
                localStorage.removeItem('userToken');
                localStorage.removeItem('userName');
                localStorage.removeItem('userRole');
                localStorage.removeItem('userLocation');

                navigate('/')
            }

        } catch (error) {
            console.error('Error fetching users:', error);
            // Handle errors appropriately (e.g., show an error message to the user)
        }
    };
    const convertTimeToDateTime = (timeString) => {
        const currentDate = new Date(); // Get the current date
        const [hours, minutes] = timeString.split(':'); // Extract hours and minutes
        currentDate.setHours(hours, minutes, 0, 0); // Set the time in the current date
        return currentDate.toISOString(); // Convert to ISO string format
    };

    const extractTimeFromString = (dateTimeString) => {
        const dateObject = new Date(dateTimeString);
        return dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // if (name === "AM_Lock" || name === "PM_Lock") {
        //     setSelectedUser({
        //         ...selectedUser,
        //         [name]: value,
        //         // [name]: value,

        //     })
        // }
        if (name === "BoxMax" || name === "StrMax") {
            setSelectedUser({ ...selectedUser, [name]: parseInt(value) });
        }
        else {
            setSelectedUser({ ...selectedUser, [name]: value });
        }

    }

    const handleChangeRole = () => {
        if (selectedRole === '') {
            alert("Please Select a User and Role to Change.");
            return;
        }

        setIsRoleLoading(true)

        fetch(`${BASE_URL}/admin/assignRole`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userToken}`,
                "content-type": "application/json"
            },
            body: JSON.stringify({ username: selectedUser.aName, role: selectedRole }),
        })
            .then((response) => {
                // if (!response.ok) {
                //     throw new Error(`HTTP error! Status: ${response.status}`);
                // }
                return response.json(); // Parse the response JSON
            })
            .then((json) => {
                // Handle the API response data here
                if (json.success === true) {
                    alert(json.message);

                    setIsRoleLoading(false);
                }
                else {
                    alert(json.message);
                    setIsRoleLoading(false);
                }
                // console.log('API Response:', json);
            })
            .catch((error) => {
                console.error('API Error:', error);
                setIsRoleLoading(false);
            });
    }

    const handleChangePassword = () => {

        if (selectedUser.label === "Select" && selectedUser.label === userName) {
            alert("Please Select an Account to Update..")
            return;
        }

        if (newPassword && newPassword !== '') {
            selectedUser.password = newPassword
        }

        selectedUser.AM_Lock = selectedUser.AM_Lock?.length >= 8 ? selectedUser.AM_Lock : selectedUser.AM_Lock + ':00'
        selectedUser.PM_Lock = selectedUser.PM_Lock?.length >= 8 ? selectedUser.PM_Lock : selectedUser.PM_Lock + ':00'
        // console.log(selectedUser.AM_Lock)

        const fieldsToRemove = ['aName', 'roles', 'cDate', 'created', 'deleted', 'modified', 'isAdmin', 'isAgent', 'isSuperAdmin'];

        // Create a new object without the specified fields
        const filteredUserObject = Object.keys(selectedUser)
            .filter(key => !fieldsToRemove.includes(key))
            .reduce((obj, key) => {
                obj[key] = selectedUser[key];
                return obj;
            }, {});

        setTimeout(() => {
            setIsLoading(true);

        }, 10);

        fetch(`${BASE_URL}/admin/updateUser/${selectedUser.aName}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userToken}`,
                "content-type": "application/json"
            },
            body: JSON.stringify(filteredUserObject),
        })
            .then((response) => {
                // if (!response.ok) {
                //     throw new Error(`HTTP error! Status: ${response.status}`);
                // }
                return response.json(); // Parse the response JSON
            })
            .then((json) => {
                // Handle the API response data here
                if (json.success === true) {
                    setMessage(json.message);

                    setIsLoading(false);
                }
                else {
                    alert(json.message);

                    setIsLoading(false)
                }
                // console.log('API Response:', json);
            })
            .catch((error) => {
                console.error('API Error:', error);
                setIsLoading(false);
            });

        setIsLoading(false)
    };

    const handleDeleteAccount = async () => {

        const userToDelete = selectedUser.aName;
        try {
            const response = await fetch(`${BASE_URL}/admin/deleteUser`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ username: userToDelete })
            });
            const json = await response.json();

            alert(json.message)
            // console.log(json.message)

            getUsers();

        } catch (error) {
            console.error('Error fetching users:', error);
            // Handle errors appropriately (e.g., show an error message to the user)
        }
    }


    return (
        <div className="change-password-container">
            <h3 className='title'>Update Account</h3>
            <form>
                {/* Current Password: */}

                <div className="profile-form">
                    <label>
                        Select User
                        <Select
                            className='select-field'
                            value={selectedUserLabel}
                            onChange={(selectedOption) => {
                                setSelectedUser(selectedOption.value);
                                setSelectedUserLabel(selectedOption);
                                setSelectedRole(selectedOption.value.isAdmin ? "ADMIN" : "AGENT")
                            }}
                            options={[
                                // { value: userName, label: 'Myself' },
                                ...users.map(user => ({ value: user, label: user.aName }))
                            ]}
                            isSearchable
                        />
                    </label>
                </div>

                {/* <div className="password-input">
                    <input
                        type={showCurrentPassword ? 'text' : 'password'}
                        id='currentPassword'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                    <label htmlFor="currentPassword">Currect Password</label>
                    <button
                        type="button"
                        className="view-password-button"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    >
                        <img src={showCurrentPassword ? eyeOpenImg : eyeClosedImg} alt="" />
                    </button>
                </div> */}
                {/* New Password: */}
                {/* <div className="form-input">
                    <input type="text" name='aName' id="username" value={selectedUser.aName} onChange={handleInputChange} required />
                    <label htmlFor="username">Username</label>
                </div> */}


                <div className="form-input">

                    <input
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        name='Password'
                        id='newPassword'
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <label htmlFor="newPassword">New Password</label>

                    <button
                        type="button"
                        className="view-password-button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                        <img src={showNewPassword ? eyeOpenImg : eyeClosedImg} alt="" />
                    </button>
                </div>

                <div className="form-input">
                    <input type="text" name='Location' id="location" value={selectedUser.Location} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="location">Location</label>
                </div>

                <div className="form-input">
                    <input type="text" name='TimeZone' id="timezone" value={selectedUser.TimeZone} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="timezone">Time Zone</label>
                </div>

                <div className="form-input">
                    <input type="text" name='BoxMax' id="boxMax" value={selectedUser.BoxMax} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="boxMax">Box Max</label>
                </div>
                <div className="form-input">
                    <input type="text" name='StrMax' id="strMax" value={selectedUser.StrMax} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="strMax">Str Max</label>
                </div>
                <div className="form-input">
                    <input type="text" name='AM_Display' id="am_display" value={selectedUser.AM_Display} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="am_display">AM_Display</label>
                </div>
                <div className="form-input">
                    <input type="time" name='AM_Lock' id="am_lock" value={selectedUser.AM_Lock} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="am_lock">AM_Lock</label>
                </div>
                <div className="form-input">
                    <input type="text" name='PM_Display' id="PM_Display" value={selectedUser.PM_Display} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="PM_Display">PM_Display</label>
                </div>
                <div className="form-input">
                    <input type="time" name='PM_Lock' id="PM_Lock" value={selectedUser.PM_Lock} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="PM_Lock">PM_Lock</label>
                </div>
                <div className="form-input">
                    <input type="text" name='Notes' id="notes" value={selectedUser.Notes} onChange={handleInputChange} required disabled={isFieldDisabled} />
                    <label htmlFor="notes">Notes</label>
                </div>

                <div className="profile-form">
                    <label>
                        Active Status:
                        <select
                            name='Active'
                            value={selectedUser.Active}
                            onChange={handleInputChange}
                        >
                            <option value={true}>Enable</option>
                            <option value={false}>Disable</option>
                        </select>
                    </label>
                </div>

                {userRole && userRole.includes("SUPERADMIN") ? (
                    <div className="profile-form">
                        <label style={{ marginBottom: 0 }}>
                            Select Role
                            <select
                                value={selectedRole}
                                onChange={(e) => setSelectedRole(e.target.value)}
                            >
                                <option value="AGENT">AGENT</option>
                                <option value="ADMIN">ADMIN</option>
                            </select>
                        </label>
                        <button style={{ fontSize: '13px', fontWeight: '600', marginBottom: '10px' }} onClick={handleChangeRole}>{isRoleLoading ? <Loader size='16px' /> : "Change Role"}</button>
                    </div>) : <></>}

                {/* Confirm New Password: */}
                {/* <div className="password-input">
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        id='confirmPassword'
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <label htmlFor="confirmPassword">Confirm New Password</label>

                    <button
                        type="button"
                        className="view-password-button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                        <img src={showConfirmPassword ? eyeOpenImg : eyeClosedImg} alt="" />
                    </button>
                </div> */}



                <button type="button" className='change-password-button' onClick={handleChangePassword}>
                    {isLoading ? <Loader size='20px' /> : "Save"}
                </button>
                <button type="btn btn-danger" style={{backgroundColor: '#dc3545', marginTop: '10px'}} className='change-password-button' onClick={handleDeleteAccount}>
                    {isDeleteLoading ? <Loader size='20px' /> : "Delete"}
                </button>
            </form>
            {message && <p className='message'>{message}</p>}
        </div>
    );
};

export default ChangePassword;
