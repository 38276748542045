import './home.css'

// import Sidebar from '../../Components/Sidebar'
// import AppNavbar from '../../Components/Navbar'
import RecordSheet from '../../Components/RecordSheet'

const Home = () => {
    return (
        <>
            {/* <Sidebar /> */}
            {/* <AppNavbar /> */}
            <RecordSheet />
        </>
    )
}

export default Home