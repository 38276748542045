import React, { useEffect, useState } from 'react';

import './report.css';

import { Button, Modal, Table, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const Report = () => {
  // Sample data for the table (replace it with your actual report data)
  // const reportsData = [
  //   {
  //     id: 1,
  //     session: '1 PM',
  //     agentName: 'John Doe',
  //     ssNumber: '121',
  //     boxAmount: 3,
  //     straightAmount: 10,
  //     total: 19,
  //     receivedTotal: 12,
  //     timeZone: 'CST',
  //     location: 'California',
  //     date: '2023-01-16',
  //     time: '11:00 AM',
  //     status: 'Approved',
  //     memo: 'No issues',
  //     dupScan: 'Yes',
  //   },
  //   {
  //     id: 2,
  //     session: '6 PM',
  //     agentName: 'Jane Smith',
  //     ssNumber: '123',
  //     boxAmount: 5,
  //     straightAmount: 10,
  //     total: 40,
  //     receivedTotal: 25,
  //     timeZone: 'EST',
  //     location: 'New York',
  //     date: '2023-01-17',
  //     time: '11:30 AM',
  //     status: 'Approved',
  //     memo: 'No issues',
  //     dupScan: 'Yes',
  //   },
  //   {
  //     id: 3,
  //     session: '1 PM',
  //     agentName: 'John Doe',
  //     ssNumber: '236',
  //     boxAmount: 3,
  //     straightAmount: 10,
  //     total: 28,
  //     receivedTotal: 24,
  //     timeZone: 'CST',
  //     location: 'California',
  //     date: '2023-01-16',
  //     time: '11:20 AM',
  //     status: 'Approved',
  //     memo: 'No issues',
  //     dupScan: 'Yes',
  //   },
  //   // Add more report data as needed
  // ];


  const userToken = localStorage.getItem('userToken')
  const userRole = localStorage.getItem('userRole')
  const userTimeZone = localStorage.getItem('userTimeZone')
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const userLocation = localStorage.getItem('userLocation')

  const initialFormData = {
    id: '',
    SS_Number: '',
    Status: '',
    Memo: '',
  };

  // const BASE_URL = 'http://localhost:5000'
  const BASE_URL = 'https://dev.soso123.me'

  const navigate = useNavigate();

  const [sessions, setSessions] = useState([]);
  const [locations, setLocations] = useState([]);

  const [filteredReports, setFilteredReports] = useState([]);
  const [agentFilter, setAgentFilter] = useState('');
  const [sessionFilter, setSessionFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');

  const [records, setRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [editingId, setEditingId] = useState(null);
  const [totalOfTotal, setTotalOfTotal] = useState(0);
  const [totalOfRecTotal, setTotalOfRecTotal] = useState(0);
  const [users, setUsers] = useState([])
  const [recordsCount, setRecordsCount] = useState(0);
  const [sosoFilter, setSoSoFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filterCleared, setFilterCleared] = useState(false);

  useEffect(() => {
    // This block of code will run when the component mounts (similar to componentDidMount)

    const currentDate = getCurrentDate();
    const parts = currentDate.split('/');
    const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    // console.log(formattedDate);

    setStartDateFilter(formattedDate)
    setEndDateFilter(formattedDate)

    // Clean-up code can be placed here if needed

    return () => {
      // Clean-up code if needed (runs when the component unmounts)
    };
  }, []);

  useEffect(() => {
    getRecords();
    getUsers();

  }, [currentPage, filterCleared])

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const totalPages = Math.ceil(recordsCount / pageSize);

  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const formatDate = (stringData) => {
    const date = new Date(stringData);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: "UTC"
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
  }

  const getCurrentDate = () => {
    switch (userTimeZone) {
      case 'PST' || 'PT':
        const currentDateInPST = new Date().toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles', day: '2-digit', month: '2-digit', year: 'numeric' });
        return (currentDateInPST);

      case 'CST' || 'CT':
        const currentDateInCST = new Date().toLocaleDateString('en-US', { timeZone: 'America/Chicago', day: '2-digit', month: '2-digit', year: 'numeric' });
        return (currentDateInCST);

      case 'EST' || 'ET':
        const currentDateInEST = new Date().toLocaleDateString('en-US', { timeZone: 'America/New_York', day: '2-digit', month: '2-digit', year: 'numeric' });
        return (currentDateInEST);

      case 'MST' || 'MT':
        const currentDateInMST = new Date().toLocaleDateString('en-US', { timeZone: 'America/Denver', day: '2-digit', month: '2-digit', year: 'numeric' });
        return (currentDateInMST);

      default:
        const defaultDate = new Date().toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles', day: '2-digit', month: '2-digit', year: 'numeric' });
        return (defaultDate);
    }
  }

  const handleFilter = () => {
    // Implement filter logic based on selected filters
    // let filteredData = reportsData;

    // if (agentFilter) {
    //   filteredData = filteredData.filter((report) => report.agentName === agentFilter);
    // }

    // if (sessionFilter) {
    //   filteredData = filteredData.filter((report) => report.session === sessionFilter);
    // }

    // if (locationFilter) {
    //   filteredData = filteredData.filter((report) => report.location === locationFilter);
    // }

    // if (startDateFilter && endDateFilter) {
    //   filteredData = filteredData.filter(
    //     (report) =>
    //       report.date >= startDateFilter && report.date <= endDateFilter
    //   );
    // }

    getRecords();



    // setFilteredReports(filteredData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData(initialFormData);
    setTimeout(() => {
      setEditingId(null);

    }, 500);
  };

  const clearAllFilter = () => {
    const currentDate = getCurrentDate();
    const parts = currentDate.split('/');
    const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    // console.log(formattedDate);

    setStartDateFilter(formattedDate);
    setEndDateFilter(formattedDate);

    setAgentFilter('');
    setSessionFilter('');
    setLocationFilter('');
    setStartDateFilter('');
    setEndDateFilter('');
    setSoSoFilter('');
    setCurrentPage(1);
    setFilterCleared(!filterCleared)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      return;
    }

    const isValidInput = /^[0-9]+$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const getRecords = async () => {
    const apiBody = {
      page: currentPage,
      pageSize: pageSize
    }

    if (agentFilter) {
      apiBody.aName = agentFilter;
    }

    if (sessionFilter) {
      apiBody.Session = sessionFilter;
    }

    if (sosoFilter) {
      apiBody.SS_Number = sosoFilter.toString();
    }

    if (locationFilter) {
      apiBody.Location = locationFilter;
    }

    if (startDateFilter === '' && endDateFilter === '') {

      const startDate = await getCurrentDate();
      const parts = startDate.split('/');

      setStartDateFilter(`${parts[2]}-${parts[0]}-${parts[1]}`)
      setEndDateFilter(`${parts[2]}-${parts[0]}-${parts[1]}`)


      apiBody.date = {
        min: startDate + ' 00:00',
        max: startDate + ' 23:59'
      }
    }

    if (startDateFilter || endDateFilter) {


      const currDate = new Date().toLocaleDateString();
      // console.log(currDate)

      const startDate = startDateFilter ? startDateFilter : '2023-01-01'
      const endDate = endDateFilter ? endDateFilter : currDate

      apiBody.date = {
        min: startDate + ' 00:00',
        max: endDate + ' 23:59'
      }
    }



    fetch(`${BASE_URL}/data`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(apiBody)
    })
      .then((response) => {
        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`);
        // }
        return response.json(); // Parse the response JSON
      })
      .then((json) => {
        // Handle the API response data here
        if (json.success === true) {
          setFilteredReports(json.data.data);
          setRecordsCount(json.data.count.recordCount);
          setTotalOfRecTotal(json.data.count.TotalOfRecTotal);

          const totalValue = json.data.data.reduce((accumulator, record) => accumulator + record.Total, 0);

          setTotalOfTotal(totalValue)
          // Now you can use the totalOfTotal value


          // console.log("Total Of Total: " + totalOfTotal)
          // const newTotalPages = Math.ceil(json.data.count.recordCount / pageSize);
          // setCurrentPage((prevPage) => Math.min(prevPage, newTotalPages));
          // console.log(json.data.data)
        }
        else {
          if (json.message === 'Token is not valid.') {
            alert("Token is not valid. Please Login Again.");
            localStorage.removeItem('userToken');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('userLocation');

            navigate('/');
          }
          // console.log('API Response:', json);

          // setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
        // setIsLoading(false);
      });

  }

  const getUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/users/getUsers`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'content-type': 'application/json'
        }
      });
      const json = await response.json();

      if (json.success) {
        setUsers(json.data);
        // console.log(json.data)
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      // Handle errors appropriately (e.g., show an error message to the user)
    }

    try {
      const response = await fetch(`${BASE_URL}/data/fetch-sessions`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'content-type': 'application/json'
        }
      });
      const json = await response.json();

      if (json.success) {
        setSessions(json.data.sessionData);
        setLocations(json.data.locationData);
        // console.log(json.data)
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      // Handle errors appropriately (e.g., show an error message to the user)
    }
  };


  const handleAddRecord = () => {
    if (editingId !== null) {

      const currentDate = new Date();

      // Format the date and time as "YYYY/MM/DD HH:mm"
      const formattedDateTime = currentDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
      });

      // Assuming the user's name is stored in formData.userName
      const addedBy = currentUser.aName;

      // Check if "Modified at" and "Added by" already exist in Memo
      let updatedMemo = formData.Memo || '';

      const byAddedByPattern = /By [^\n]+/;
      const datePattern = /\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2}/;

      const byAddedByMatch = updatedMemo.match(byAddedByPattern);
      const dateMatch = updatedMemo.match(datePattern);

      if (byAddedByMatch && dateMatch) {
        // Update existing "By [added by]" and date information
        updatedMemo = updatedMemo.replace(byAddedByPattern, `By ${addedBy}`);
        updatedMemo = updatedMemo.replace(datePattern, formattedDateTime);
      } else {
        // Append the information if not already present
        updatedMemo += `\n\nBy ${addedBy}\n${formattedDateTime}`;
      }


      fetch(`${BASE_URL}/data/update/${formData.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          Status: formData.Status,
          Memo: updatedMemo,
        }),
      })
        .then((response) => {
          // if (!response.ok) {
          //     throw new Error(`HTTP error! Status: ${response.status}`);
          // }
          return response.json(); // Parse the response JSON
        })
        .then((json) => {
          // Handle the API response data here
          if (json.success === true) {
            getRecords();
            alert(json.message);

            setEditingId(null);
            setFormData(initialFormData);
            handleCloseModal();
          }
          else {
            alert(json.message);
            // console.log('API Response:', json);

            // setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error('API Error:', error);
          // setIsLoading(false);
        });

      setEditingId(null);
      setFormData(initialFormData);
      handleCloseModal();

      // console.log("Record Edit")
      return
    }


    fetch(`${BASE_URL}/data/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        SS_Number: parseInt(formData.SS_Number, 10),
        Box_Amount: parseInt(formData.Box_Amount, 10),
        Straight_Amount: parseInt(formData.Straight_Amount, 10),
        Total: formData.Total,
        Rec_Total: formData.Rec_Total
      }),
    })
      .then((response) => {
        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`);
        // }
        return response.json(); // Parse the response JSON
      })
      .then((json) => {
        // Handle the API response data here
        if (json.success === true) {
          getRecords();
        }
        else {
          alert(json.message);
          // console.log('API Response:', json);

          // setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
        // setIsLoading(false);
      });
    // Clear form data
    setFormData(initialFormData);
    handleCloseModal();
  };

  const handleEditRecord = (index) => {

    if (userRole === 'AGENT') {
      return;
    }

    const recordToEdit = filteredReports[index];

    const modifiedDate = new Date(recordToEdit.modified);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = currentDate - modifiedDate;

    // Calculate the difference in days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    // Check if the modified date is more than 7 days old
    if (daysDifference > 7 && !(userRole && userRole.includes("SUPERADMIN"))) {
      // Return from the function or show an alert
      alert('Record is more than 7 days old. Cannot edit.');
      return;
    }

    // console.log(recordToEdit)
    setFormData(recordToEdit);
    setEditingId(index);
    setShowModal(true);
  };


  return (
    <div className='container'>
      <h2 className='title'>Reports</h2>



      <div className="filters">
        <div className='row1'>
          {userRole !== 'AGENT' ?
            (<div className='form-input'>
              <label>Agent</label>
              <select
                value={agentFilter}
                onChange={(e) => setAgentFilter(e.target.value)}
              >
                <option value="">All</option>
                {
                  users.map((user, index) => (
                    <option key={index} value={user.aName}>{user.aName}</option>
                  ))
                }
              </select>
            </div>
            ) : <></>}
          <div className='form-input'>
            <label>Session</label>
            <select
              value={sessionFilter}
              onChange={(e) => setSessionFilter(e.target.value)}
            >
              <option value="">All</option>
              {
                sessions.map((session, index) => (
                  session.Session && session.Session !== '' ? <option key={index} value={session.Session}>{session.Session}</option> : null
                ))
              }
            </select>
          </div>

          {userRole !== 'AGENT' ?
            (
              <div className='form-input'>
                <label>Location</label>
                <select
                  value={locationFilter}
                  onChange={(e) => setLocationFilter(e.target.value)}
                >
                  <option value="">All</option>
                  {
                    locations.map((location, index) => (
                      location.Location && location.Location !== '' ? <option key={index} value={location.Location}>{location.Location}</option> : null
                    ))
                  }
                </select>
              </div>
            ) : <></>}
        </div>
        <div className='row2'>

          <div className='session-total-data'>
            <div className='form-input' style={{ display: 'block' }}>
              <label>Date Range</label>
              <input
                type="date"
                value={startDateFilter}
                onChange={(e) => {
                  setStartDateFilter(e.target.value);
                  // console.log(e.target.value) 
                }}
              />
              <p style={{ textAlign: 'center', margin: 0 }}> to </p>
              <input
                type="date"
                value={endDateFilter}
                onChange={(e) => setEndDateFilter(e.target.value)}
              />
            </div>
            {/* <div className='data-col'>
          <div className='data-field'>
            <p>Loc: </p>
            <strong><p>{userLocation}</p></strong>
          </div>
          <div className='data-field'>
            <p>Ses:</p>
            <strong><p>{currentSession}</p></strong>
          </div>
        </div> */}

            <div className='data-col'>
              <div className='form-item'>

                <input
                  type="number"
                  // placeholder="Enter SS Number"
                  id='soso'
                  name="SS_Number"
                  min={0}
                  value={sosoFilter}
                  onChange={(e) => setSoSoFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <label htmlFor="soso">Search SoSo</label>
              </div>

              <div className='data-field'>
                <p>Due: </p>
                <strong><p>{totalOfRecTotal}</p></strong>
              </div>
              <div className='data-field'>
                <p>Cnt: </p>
                <strong><p>{recordsCount}</p></strong>
              </div>
              <div className='data-field'>
                <p>Dis: </p>
                <strong><p>{totalOfRecTotal - totalOfTotal}</p></strong>
              </div>
            </div>
          </div>
        </div>
        <div className='btn-container'>
          <button type="button" className='filter-button' onClick={handleFilter}>
            Search
          </button>
          <button type="button" className='clear-button' onClick={clearAllFilter}>
            Clear Search
          </button>
        </div>
      </div>
      <Table striped bordered hover responsive>
        {userRole === 'AGENT' ?
          <>
            <thead>
              <tr>
                <th>ID</th>
                <th>SoSo</th>
                <th>Session</th>
                <th>Box</th>
                <th>Straight</th>
                <th>Total</th>
                <th>RTotal</th>
                <th>Agent</th>
                <th>Time Zone</th>
                <th>Location</th>
                <th>Date SoSo</th>
                <th>Created</th>
                <th>Status</th>
                <th>DupScan</th>
                <th>Memo</th>
              </tr>
            </thead>
            <tbody>
              {filteredReports.map((report, index) => (
                <tr key={report.id}>
                  <td>{report.id}</td>
                  <td onClick={() => handleEditRecord(index)}
                    className='ss-number'>{report.SS_Number}</td>
                  <td>{report.Session}</td>
                  <td>{report.Box_Amount}</td>
                  <td>{report.Straight_Amount}</td>
                  <td>{report.Total}</td>
                  <td>{report.Rec_Total}</td>
                  <td>{report.aName}</td>
                  <td>{report.TimeZone}</td>
                  <td>{report.Location}</td>
                  <td>{report.modified && formatDate(report.modified)}</td>
                  <td>{report.cDate && formatDate(report.cDate)}</td>
                  <td>{report.Status}</td>
                  <td>{report.DupScan}</td>
                  <td style={{ textAlign: 'left' }}>{report.Memo}</td>
                </tr>
              ))}
            </tbody>
          </>
          :
          <>
            <thead>
              <tr>
                <th>ID</th>
                <th>SoSo</th>
                <th>Total</th>
                <th>RTotal</th>
                <th>DupScan</th>
                <th>Box</th>
                <th>Straight</th>
                <th>Session</th>
                <th>Agent</th>
                <th>Time Zone</th>
                <th>Location</th>
                <th>Created</th>
                <th>Date SoSo</th>
                <th>Status</th>
                <th>Memo</th>
              </tr>
            </thead>
            <tbody>
              {filteredReports.map((report, index) => (
                <tr key={report.id}>
                  <td>{report.id}</td>
                  <td onClick={() => handleEditRecord(index)}
                    className='ss-number'>{report.SS_Number}</td>
                  <td>{report.Total}</td>
                  <td>{report.Rec_Total}</td>
                  <td>{report.DupScan}</td>
                  <td>{report.Box_Amount}</td>
                  <td>{report.Straight_Amount}</td>
                  <td>{report.Session}</td>
                  <td>{report.aName}</td>
                  <td>{report.TimeZone}</td>
                  <td>{report.Location}</td>
                  <td>{report.cDate && formatDate(report.cDate)}</td>
                  <td>{report.modified && formatDate(report.modified)}</td>
                  <td>{report.Status}</td>
                  <td style={{ textAlign: 'left' }}>{report.Memo}</td>
                </tr>
              ))}
            </tbody>
          </>}
      </Table>
      {filteredReports && filteredReports.length === 0 ? <div className='no-records'>No Records Found.</div>
        :
        recordsCount > pageSize ? (

          <div className='pagination-buttons'>
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>

            {/* Render page buttons */}
            {pageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </button>
            ))}

            <button onClick={handleNextPage} disabled={currentPage >= totalPages}>
              Next
            </button>
          </div>
        ) : null
      }

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingId !== null ? 'Edit Record' : 'Add Record'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className='top-fields'>
              <Form.Group controlId="formName" className='form-input'>
                <Form.Label>SoSo</Form.Label>
                <Form.Control
                  type="number"
                  // placeholder="Enter SS Number"
                  name="SS_Number"
                  value={formData.SS_Number}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="formAge" className='form-input'>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="Status"
                  value={formData.Status}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Open">Open</option>
                  <option value="Winner">Winner</option>
                </Form.Select>
              </Form.Group>
            </div>

            <Form.Group controlId="formEmail" className='form-input'>
              <Form.Label>Memo</Form.Label>
              <Form.Control
                as='textarea'
                // placeholder="Enter Memo"
                name="Memo"
                value={formData.Memo}
                onChange={handleInputChange}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDeleteRecord}>
      Delete
    </Button> */}
          <Button variant="primary" onClick={handleAddRecord}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

    </div >
  );
};

export default Report;
