import { useState } from 'react';
import './navbar.css'

import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import logoBlue from './../../Assets/Images/Navbar/logo-blue.png'

const AppNavbar = () => {
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(false)

  const userName = localStorage.getItem('userName')
  const userRole = localStorage.getItem('userRole')
  const userToken = localStorage.getItem('userToken')

  // const BASE_URL = 'http://localhost:5000'
  const BASE_URL = 'https://dev.soso123.me'

  const handleLogout = async () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userLocation');

    try {
      const response = await fetch(`${BASE_URL}/users/logout`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        // Successful logout, you can perform additional actions if needed
        console.log('Logout successful');
        navigate('/')

      } else {
        // Handle errors, you can check the response status and handle accordingly
        console.error('Logout failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during logout:', error.message);
    }


    navigate('/')

  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container className='nav-container'>
        <Navbar.Brand as={Link} to="/home"><img src={logoBlue} alt="SoSo" width={90} /></Navbar.Brand>
        <Navbar.Toggle data-bs-target="#navbar-collapsse" data-bs-toggle="collapse" aria-controls="navbar-collapse" />
        <Navbar.Collapse id="navbar-collapse">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/home">Home</Nav.Link>
            {/* <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link> */}
            <NavDropdown title={userName} onClick={() => setOpenDropdown(!openDropdown)} id="basic-nav-dropdown" show={window.innerWidth > 992 ? true : openDropdown}>
              <NavDropdown.Item as={Link} to="/user-profile">Your Profile</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/report">Reports</NavDropdown.Item>
              {
                userRole && userRole.includes("ADMIN") || userRole && userRole.includes("SUPERADMIN") ? <>
                  <NavDropdown.Item as={Link} to="/update-account/NaN">
                    Update Account
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/signup">Create Account</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/users-accounts">All Accounts</NavDropdown.Item>
                </>
                  : <></>
              }
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/" onClick={handleLogout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AppNavbar;