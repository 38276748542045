import React from 'react';
import PropTypes from 'prop-types';
import './loader.css'; // You can style your loader in a separate CSS file

const Loader = ({ size }) => {
  const loaderStyle = {
    width: size,
    height: size,
  };

  return (
    <div className="loader" style={loaderStyle}>
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string.isRequired,
};

export default Loader;
