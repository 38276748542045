import './recordSheet.css'

// components/RecordSheet.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const RecordSheet = () => {

    const userName = localStorage.getItem('userName')
    const userToken = localStorage.getItem('userToken')
    const userLocation = localStorage.getItem('userLocation')
    const userTimeZone = localStorage.getItem('userTimeZone')
    const currentUser = localStorage.getItem('currentUser')
    const BoxMax = parseInt(localStorage.getItem('BoxMax'), 10)
    const StrMax = parseInt(localStorage.getItem('StrMax'), 10)

    const navigate = useNavigate();

    // const BASE_URL = 'http://localhost:5000'
    const BASE_URL = 'https://dev.soso123.me'

    const initialFormData = {
        id: '',
        SS_Number: '',
        Box_Amount: '',
        Straight_Amount: '',
        Total: '',
        Rec_Total: '',
    };


    const [records, setRecords] = useState([]);
    const [boxValue, setBoxValue] = useState(6);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [editingId, setEditingId] = useState(null);
    const [totalOfTotal, setTotalOfTotal] = useState(0);
    const [totalOfRecTotal, setTotalOfRecTotal] = useState(0);
    const [recordsCount, setRecordsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [currentSession, setCurrentSession] = useState();
    const [isEntryLocked, setIsEntryLocked] = useState(false);
    // const [currentDate, setCurrentDate] = useState();

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const totalPages = Math.ceil(recordsCount / pageSize);

    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getCurrentSession();

        }, 20000); // 20 seconds in milliseconds

        // Clear the interval when the component is unmounted or otherwise cleaned up
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // getCurrentSession();
        getRecords();


    }, [currentPage])

    const formatDate = (stringData) => {
        const date = new Date(stringData);

        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC'
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        return formattedDate;
    }

    const getCurrentSession = () => {
        let timeNow
        switch (userTimeZone) {
            case 'PST' || 'PT':
                const pstTime = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
                timeNow = new Date(pstTime)
                // console.log('Performing actions for PST time zone');
                break;

            case 'CST' || 'CT':
                const cstTime = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' });
                timeNow = new Date(cstTime)
                // console.log('Performing actions for CST time zone');
                break;

            case 'EST' || 'ET':
                const estTime = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
                timeNow = new Date(estTime);
                // console.log('Performing actions for EST time zone');
                break;

            case 'MST' || 'MT':
                const mstTime = new Date().toLocaleString('en-US', { timeZone: 'America/Denver' });
                timeNow = new Date(mstTime);
                // console.log('Performing actions for MST time zone');
                break;

            default:
                const defaultTime = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
                timeNow = new Date(defaultTime)
                // console.log('Performing default PST actions');
                break;
        }

        const currentDateInPST = new Date().toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
        const currentDateInCST = new Date().toLocaleDateString('en-US', { timeZone: 'America/Chicago' });

        // console.log(currentDateInPST)
        // console.log(currentDateInCST)
        // console.log("Session Checked.")

        // console.log("Time Now: " + timeNow.getHours())
        const userObj = JSON.parse(currentUser)
        // const amLock = new Date(userObj.AM_Lock.split(":")[0])
        const amLockHours = userObj.AM_Lock.split(":")[0]
        const amLockMins = userObj.AM_Lock.split(":")[1]
        const pmLockHours = userObj.PM_Lock.split(":")[0]
        const pmLockMins = userObj.PM_Lock.split(":")[1]

        // console.log("AM_Lock Hours: " + amLockHours)
        // console.log("AM_Lock Mins: " + amLockMins)
        // console.log("PM_Lock Hours: " + pmLockHours)
        // console.log("PM_Lock Mins: " + pmLockMins)

        // console.log(timeNow.getHours())
        // console.log(timeNow.getMinutes())
        if (timeNow.getHours() >= amLockHours && timeNow.getHours() <= pmLockHours) {
            if (timeNow.getHours() == amLockHours && timeNow.getMinutes() <= amLockMins) {
                setCurrentSession(userObj.AM_Display)
                setIsEntryLocked(false);
                return (userObj.AM_Display)
            }
            else if (timeNow.getHours() == amLockHours && timeNow.getMinutes() > amLockMins && timeNow.getMinutes() <= (parseInt(amLockMins, 10) + 10) % 60) {
                setCurrentSession(userObj.AM_Display);
                setIsEntryLocked(true);
                return userObj.AM_Display;
            }

            if (timeNow.getHours() == pmLockHours && timeNow.getMinutes() >= (parseInt(pmLockMins, 10) + 10) % 60) {
                setCurrentSession(userObj.AM_Display)
                setIsEntryLocked(false)
                return (userObj.AM_Display)
            }
            else if (timeNow.getHours() == pmLockHours && timeNow.getMinutes() > pmLockMins && timeNow.getMinutes() <= (parseInt(pmLockMins, 10) + 10) % 60) {
                setCurrentSession(userObj.PM_Display)
                setIsEntryLocked(true);
                return (userObj.PM_Display)
            }
            else {
                setCurrentSession(userObj.PM_Display)
                setIsEntryLocked(false);
                return (userObj.PM_Display)
            }
        } else {
            setCurrentSession(userObj.AM_Display)
            setIsEntryLocked(false);
            return (userObj.AM_Display)
        }
    };

    const getHoursAndMinutesFromString = (dateTimeString) => {
        // Split the string into date, time, and timezone
        const [datePart, timePart, timeZonePart] = dateTimeString.split(', ');

        // Extract hours and minutes from the timePart
        const [hours, minutes] = timePart.split(':').map(Number);

        // Adjust the hours based on the time zone
        if (timeZonePart === 'PST') {
            return {
                hours: (hours - 8 + 24) % 24, // Adjust for PST (UTC-8)
                minutes,
            };
        } else if (timeZonePart === 'EST') {
            return {
                hours: (hours - 5 + 24) % 24, // Adjust for EST (UTC-5)
                minutes,
            };
        } else if (timeZonePart === 'MST') {
            return {
                hours: (hours - 7 + 24) % 24, // Adjust for MST (UTC-7)
                minutes,
            };
        } else if (timeZonePart === 'CST') {
            return {
                hours: (hours - 6 + 24) % 24, // Adjust for CST (UTC-6)
                minutes,
            };
        }
        // Add more conditions for other time zones if needed

        // Return the extracted hours and minutes
        return {
            hours,
            minutes,
        };
    }

    const getCurrentDate = async () => {
        const targetTimeZones = {
            PST: 'America/Los_Angeles',
            EST: 'America/New_York',
            CST: 'America/Chicago',
            MST: 'America/Denver'
        };

        const userObj = JSON.parse(currentUser);
        const date = new Date();

        // console.log("Date Right Now: " + date)
        const formattedDate = date.toLocaleString('en-US', {
            timeZone: targetTimeZones[userTimeZone],
            hour12: false, // Use 24-hour format if needed
            timeZoneName: 'short', // Display time zone abbreviation
        });

        let zoneDate = date.toLocaleString('en-US', {
            timeZone: targetTimeZones[userTimeZone],
        });
        zoneDate = new Date(zoneDate);

        const { hours, minutes } = getHoursAndMinutesFromString(formattedDate);

        const amSession = parseInt(userObj.AM_Lock.split(":")[0]);
        const amSessionMinutes = parseInt(userObj.AM_Lock.split(":")[1]);
        const pmSession = parseInt(userObj.PM_Lock.split(":")[0]);
        const pmSessionMinutes = parseInt(userObj.PM_Lock.split(":")[1]);

        // console.log(amSession % 12, pmSession % 12, amSessionMinutes, pmSessionMinutes);

        if (hours > pmSession || (hours === pmSession && minutes >= pmSessionMinutes)) {
            zoneDate.setDate(zoneDate.getDate() + 1);
        }

        const finalDate = zoneDate.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });

        return finalDate;
    };


    // const getCurrentDate = async () => {
    //     const targetTimeZones = {
    //         PST: 'America/Los_Angeles',
    //         EST: 'America/New_York',
    //         CST: 'America/Chicago',
    //         MST: 'America/Denver'
    //     }

    //     const userObj = JSON.parse(currentUser)
    //     const date = new Date();
    //     const formattedDate = date.toLocaleString('en-US', {
    //         timeZone: targetTimeZones[userTimeZone],
    //         hour12: false, // Use 24-hour format if needed
    //         timeZoneName: 'short', // Display time zone abbreviation
    //     });
    //     let zoneDate = date.toLocaleString('en-US', {
    //         timeZone: targetTimeZones[userTimeZone],
    //         // Display time zone abbreviation
    //     });
    //     console.log(formattedDate, "ffffffffffffffffff", zoneDate)
    //     zoneDate = new Date(zoneDate)

    //     const { hours, minutes } = getHoursAndMinutesFromString(formattedDate)
    //     // const hours=19;
    //     // const minutes=38;
    //     const amSession = parseInt(userObj.AM_Lock.split(":")[0])
    //     const amSessionMinutes = parseInt(userObj.AM_Lock.split(":")[1])
    //     const pmSession = parseInt(userObj.PM_Lock.split(":")[0])
    //     const pmSessionMinutes = parseInt(userObj.PM_Lock.split(":")[1])


    //     console.log(amSession % 12, pmSession % 12, amSessionMinutes, pmSessionMinutes)
    //     let sessions = [userObj.AM_Display, userObj.PM_Display]

    //     if (hours > pmSession || (hours == pmSession && minutes >= pmSessionMinutes)) {
    //         // if (hours > 0 || (hours == 0 && minutes >= pmSessionMinutes)) {
    //         // const tomorrow = new Date('en-US', { timeZone: targetTimeZones[userTimeZone] });
    //         // tomorrow.setDate(tomorrow.getDate() + 1);
    //         // const nextDate = tomorrow.toISOString().split('T')[0];
    //         // return nextDate;
    //         const tomorrow = new Date();
    //         const timeZoneOptions = { timeZone: targetTimeZones[userTimeZone] };
    //         const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
    //             ...timeZoneOptions,
    //             year: 'numeric',
    //             month: '2-digit',
    //             day: '2-digit',
    //             hour: '2-digit',
    //             minute: '2-digit',
    //             second: '2-digit',
    //             hour12: false
    //         });

    //         const tomorrowInTimeZone = dateTimeFormatter.format(tomorrow);
    //         const nextDate = new Date(tomorrowInTimeZone);
    //         await nextDate.setDate(nextDate.getDate() + 1);
    //         // const formattedDate = await nextDate.toISOString().split('T')[0];
    //         return (nextDate);
    //     }
    //     else {
    //         const currentDateInPST = new Date().toLocaleDateString('en-US', { timeZone: targetTimeZones[userTimeZone] });
    //         return (currentDateInPST);
    //     }
    // }

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            return;
        }

        const isValidInput = /^[0-9]+$/.test(e.key);
        if (!isValidInput) {
            e.preventDefault();
        }
    }

    const handlePaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text');

        // Check if pasted content contains non-numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            e.preventDefault();
        }
    }

    const handleInputChange = (e) => {

        e.target.value = e.target.value.replace(/[^0-9]/g, '');

        const { name, value } = e.target;
        if (name === "Box_Amount") {
            const intValue = parseInt(value, 10);
            if (intValue > BoxMax) {
                alert("Please keep the Box value under " + BoxMax)
                return;
            }
        }
        if (name === "Straight_Amount") {
            const intValue = parseInt(value, 10);
            if (intValue > StrMax) {
                alert("Please keep the Straight value under " + StrMax)
                return;
            }
        }
        setFormData({ ...formData, [name]: value });

        if (name === "SS_Number" || name === "Box_Amount" || name === "Straight_Amount") {
            // console.log("first run")


            const SS_Number = parseInt(formData.SS_Number, 10);
            const Box_Amount = parseInt(name === "Box_Amount" ? value : formData.Box_Amount, 10);
            const Straight_Amount = parseInt(name === "Straight_Amount" ? value : formData.Straight_Amount, 10);

            let total;

            if (name === "SS_Number") {
                setBoxValue(calculateBoxValue(value));
            }

            // console.log("boxValue: ", boxValue)

            if ((name !== 'SS_Number' && (formData.SS_Number?.toString().length === 3 || formData.SS_Number?.toString().length === 4)) ||
                (name === 'SS_Number' && (value?.length === 3 || value?.length === 4))) {
                const returnedBoxValue = calculateBoxValue(name === 'SS_Number' ? value : formData.SS_Number?.toString())
                total = (Box_Amount * returnedBoxValue) + Straight_Amount;
            }

            setFormData({ ...formData, [name]: value, Total: total, Rec_Total: total })
        }
    }

    const calculateBoxValue = (ssNumber) => {
        const uniqueDigits = new Set(ssNumber.split(''));
        const digitCount = uniqueDigits.size;

        if (ssNumber.length === 4) {
            return 25
        }
        else if (ssNumber.length === 3) {
            if (digitCount === 1) {
                return 1;
            } else if (digitCount === 2) {
                return 3;
            } else {
                return 6;
            }
        }
    };

    const handleLogout = async () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userLocation');
        localStorage.removeItem('user');

        try {
            const response = await fetch(`${BASE_URL}/users/logout`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (response.ok) {
                // Successful logout, you can perform additional actions if needed
                console.log('Logout successful');
                navigate('/')

            } else {
                // Handle errors, you can check the response status and handle accordingly
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error.message);
        }


        navigate('/')

    }

    const getRecords = async () => {

        const currSession = await getCurrentSession();
        let currentDate = await getCurrentDate();

        // const formattedDate = await currentDate.toISOString().split('T')[0];

        // console.log(typeof (currentDate))
        // console.log(currentDate.toISOString())

        fetch(`${BASE_URL}/data`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                // SS_Number: 123,
                // aName: "Test",
                Session: currSession,
                page: currentPage,
                pageSize: pageSize,
                date: {
                    // min: "2023-12-22",
                    // max: "2023-12-22"
                    // min: ((currentDate instanceof Date) ? currentDate.toISOString().split('T')[0] : currentDate) + ' 00:00',
                    min: currentDate + ' 00:00',
                    max: "3023-12-31" + ' 23:59'
                }
            })
        })
            .then((response) => {
                // if (!response.ok) {
                //     throw new Error(`HTTP error! Status: ${response.status}`);
                // }
                return response.json(); // Parse the response JSON
            })
            .then((json) => {
                // Handle the API response data here
                if (json.success === true) {
                    setRecords(json.data.data);
                    setRecordsCount(json.data.count.recordCount);
                    setTotalOfRecTotal(json.data.count.TotalOfRecTotal);

                    const totalValue = json.data.data.reduce((accumulator, record) => accumulator + record.Total, 0);

                    setTotalOfTotal(totalValue)

                    // json.data.data.map((record, index) => {
                    //     setTotalOfRecTotal(totalOfRecTotal + record.RecTotal)
                    // })
                    const newTotalPages = Math.ceil(json.data.count.recordCount / pageSize);
                    setCurrentPage((prevPage) => Math.min(prevPage, newTotalPages === 0 ? 1 : newTotalPages));
                    // console.log(json.data.data)
                }
                else {
                    if (json.message === 'Token is not valid.') {
                        alert("Token is not valid. Please Login Again.");
                        localStorage.removeItem('userToken');
                        localStorage.removeItem('userName');
                        localStorage.removeItem('userRole');
                        localStorage.removeItem('userLocation');

                        navigate('/')
                        // handleLogout();
                    }
                    // console.log('API Response:', json);

                    // setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                // setIsLoading(false);
            });

    }


    const handleAddRecord = () => {

        if (formData.SS_Number?.length > 4 || formData.SS_Number?.length < 3) {
            alert("SoSo value should be 3 or 4 digits only.")
            return;
        }

        if (formData.Rec_Total === '') {
            alert("Received Total can't be empty.")
            return;
        }

        if (formData.Rec_Total > formData.Total) {
            alert("Received Total should be less than Total.")
            return;
        }

        // console.log("Type of Soso: " + typeof (formData.SS_Number))

        if (editingId !== null) {
            // Edit existing record

            fetch(`${BASE_URL}/data/update/${formData.id}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    SS_Number: formData.SS_Number.toString(),
                    Box_Amount: parseInt(formData.Box_Amount, 10),
                    Straight_Amount: parseInt(formData.Straight_Amount, 10),
                    Total: formData.Total,
                    Rec_Total: formData.Rec_Total
                }),
            })
                .then((response) => {
                    // if (!response.ok) {
                    //     throw new Error(`HTTP error! Status: ${response.status}`);
                    // }
                    return response.json(); // Parse the response JSON
                })
                .then((json) => {
                    // Handle the API response data here
                    if (json.success === true) {
                        getRecords();
                        alert(json.message);

                        setEditingId(null);
                        setFormData(initialFormData);
                        handleCloseModal();
                    }
                    else {
                        alert(json.message);
                        // console.log('API Response:', json);

                        // setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.error('API Error:', error);
                    // setIsLoading(false);
                });

            setEditingId(null);
            setFormData(initialFormData);
            handleCloseModal();

            // console.log("Record Edit")
            return
        }


        fetch(`${BASE_URL}/data/create`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userToken}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                SS_Number: formData.SS_Number.toString(),
                Box_Amount: parseInt(formData.Box_Amount, 10),
                Straight_Amount: parseInt(formData.Straight_Amount, 10),
                Total: formData.Total,
                Rec_Total: formData.Rec_Total
            }),
        })
            .then((response) => {
                // if (!response.ok) {
                //     throw new Error(`HTTP error! Status: ${response.status}`);
                // }
                return response.json(); // Parse the response JSON
            })
            .then((json) => {
                // Handle the API response data here
                if (json.success === true) {
                    getRecords();
                }
                else {
                    alert(json.message);
                    // console.log('API Response:', json);

                    // setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                // setIsLoading(false);
            });
        // Clear form data
        setFormData(initialFormData);
        handleCloseModal();
    };

    const handleEditRecord = (index) => {
        if (isEntryLocked) {
            return;
        }
        const recordToEdit = records[index];
        setFormData(recordToEdit);
        setEditingId(index);
        setShowModal(true);
    };

    const handleDeleteRecord = () => {
        if (editingId !== null) {

            fetch(`${BASE_URL}/data/delete/${formData.id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'content-type': 'application/json'
                }
            })
                .then((response) => {
                    // if (!response.ok) {
                    //     throw new Error(`HTTP error! Status: ${response.status}`);
                    // }
                    return response.json(); // Parse the response JSON
                })
                .then((json) => {
                    // Handle the API response data here
                    if (json.success === true) {
                        alert(json.message)
                        getRecords();

                        setShowModal(false);
                        setFormData(initialFormData);
                        setEditingId(null)
                    }
                    else {
                        alert(json.message);
                        // console.log('API Response:', json);

                        // setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.error('API Error:', error);
                    // setIsLoading(false);
                });

            setShowModal(false);
            setFormData(initialFormData);
            setEditingId(null)
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData(initialFormData);
        setTimeout(() => {
            setEditingId(null);

        }, 500);
    };

    return (
        <div className='container'>
            <h2 className='title'>Record Sheet</h2>

            {isEntryLocked ?
                <div className='locked-session-container'>
                    <p className='locked-session-message'>Session Locked! No Record Entry till {currentSession}</p>
                </div> : <></>
            }

            <div className='session-data'>
                <div className='data-col'>
                    <div className='data-field'>
                        <p>Loc: </p>
                        <strong><p>{userLocation}</p></strong>
                    </div>
                    <div className='data-field'>
                        <p>Ses:</p>
                        <strong><p>{currentSession}</p></strong>
                    </div>
                </div>
                <div className='data-col'>
                    <div className='data-field'>
                        <p>Due: </p>
                        <strong><p>{totalOfRecTotal}</p></strong>
                    </div>
                    <div className='data-field'>
                        <p>Cnt: </p>
                        <strong><p>{recordsCount}</p></strong>
                    </div>
                    <div className='data-field'>
                        <p>Dis: </p>
                        <strong><p>{totalOfRecTotal - totalOfTotal}</p></strong>
                    </div>
                </div>
            </div>

            <Button className='add-record' onClick={() => setShowModal(true)} disabled={isEntryLocked}>
                Add Record
            </Button>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        {/* <th>ID</th> */}
                        {/* <th>Agent Name</th> */}
                        {/* <th>Session</th> */}
                        <th>SoSo</th>
                        <th>Box</th>
                        <th>Str</th>
                        {/* <th>Total</th> */}
                        <th>RTotal</th>
                        <th>Date SoSo</th>
                        {/* <th>Actions</th> */}
                    </tr>
                </thead>
                <tbody>
                    {records.map((record, index) => (
                        <tr key={record.id}>
                            {/* <td>{record.id}</td> */}
                            {/* <td>{record.aName}</td> */}
                            {/* <td>{record.session}</td> */}
                            <td
                                onClick={() => handleEditRecord(index)}
                                className='ss-number'
                            >{record.SS_Number}</td>
                            <td>{record.Box_Amount}</td>
                            <td>{record.Straight_Amount}</td>
                            {/* <td>{record.total}</td> */}
                            <td>{record.Rec_Total}</td>
                            <td>{formatDate(record.modified)}</td>
                            {/* <td style={{ whiteSpace: 'nowrap' }}>
                                <Button variant="info" onClick={() => handleEditRecord(index)}>
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeleteRecord(record.id)}
                                >
                                    Delete
                                </Button>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {
                recordsCount >= pageSize ?

                    <div className='pagination-buttons'>
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>

                        {/* {pageNumbers.length > 1 && <span>...</span>} */}

                        {/* Render page buttons */}
                        {pageNumbers.map((pageNumber) => (
                            <button
                                key={pageNumber}
                                onClick={() => setCurrentPage(pageNumber)}
                                disabled={currentPage === pageNumber}
                            >
                                {pageNumber}
                            </button>
                        ))}

                        {/* {pageNumbers[pageNumbers.length-1] < pageNumbers.length && <span>...</span>} */}

                        <button onClick={handleNextPage} disabled={currentPage >= totalPages}>
                            Next
                        </button>
                    </div>
                    : null
            }

            {records === null || records.length === 0 ? <div className='no-records'>No Records Entered</div> : <></>}

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingId !== null ? 'Edit Record' : 'Add Record'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formName" className='form-input'>
                            <Form.Label>SoSo</Form.Label>
                            <Form.Control
                                type="number"
                                // placeholder="Enter SS Number"
                                name="SS_Number"
                                min={0}
                                value={formData.SS_Number}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                onPaste={handlePaste}
                            />
                        </Form.Group>

                        <div className='single-row'>
                            <Form.Group controlId="formAge" className='form-input'>
                                <Form.Label>Box</Form.Label>
                                <Form.Control
                                    type="number"
                                    // placeholder="Enter Box Amount"
                                    name="Box_Amount"
                                    min={0}
                                    max={BoxMax}
                                    value={formData.Box_Amount}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail" className='form-input'>
                                <Form.Label>Straight</Form.Label>
                                <Form.Control
                                    type="number"
                                    // placeholder="Enter Straight Amount"
                                    name="Straight_Amount"
                                    min={0}
                                    max={StrMax}
                                    value={formData.Straight_Amount}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </div>

                        <div className='single-row'>
                            <Form.Group controlId="formCity" className='form-input'>
                                <Form.Label>Total</Form.Label>
                                <Form.Control
                                    type="number"
                                    // placeholder="Total"
                                    name="Total"
                                    value={formData.Total}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </Form.Group>

                            <Form.Group controlId="formCountry" className='form-input'>
                                <Form.Label>Received Total</Form.Label>
                                <Form.Control
                                    type="number"
                                    // placeholder="Received Total"
                                    name="Rec_Total"
                                    value={formData.Rec_Total}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteRecord}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleAddRecord}>
                        {/* {editingId !== null ? 'Edit Record' : 'Add Record'} */}
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RecordSheet;
