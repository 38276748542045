import { useState } from 'react';
import './../Signup/signup.css'
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader';

import logoWhite from './../../Assets/Images/logo-white.png'

const Login = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    // const BASE_URL = 'http://localhost:5000'
    const BASE_URL = 'https://dev.soso123.me'

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const userData = {
        username: username,
        password: password,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    };

    const handleLogin = () => {

        if (password === '' || username === '') {
            alert('Please Enter Required details in All Fields')
            return;
        }
        setTimeout(() => {
            setIsLoading(true);
            
        }, 10);

        fetch(`${BASE_URL}/auth/sign-in`, requestOptions)
            .then((response) => {
                // if (!response.ok) {
                //     throw new Error(`HTTP error! Status: ${response.status}`);
                // }
                return response.json(); // Parse the response JSON
            })
            .then((json) => {
                // Handle the API response data here
                if (json.success === true) {
                    if(!json.data.user.Active) {
                        alert('User is Disabled. Please Contact to Admin.')
                        setIsLoading(false);
                        return;
                    }
                    // console.log('API Response:', json);
                    localStorage.setItem('userToken', json.token);
                    // console.log(json.token);
                    localStorage.setItem('currentUser', JSON.stringify(json.data.user));
                    localStorage.setItem('userName', json.data.user.aName);
                    localStorage.setItem('userLocation', json.data.user.Location);
                    localStorage.setItem('userTimeZone', json.data.user.TimeZone);
                    localStorage.setItem('userRole', json.data.user.roles);
                    localStorage.setItem('BoxMax', json.data.user.BoxMax);
                    localStorage.setItem('StrMax', json.data.user.StrMax);
                    // console.log(json.data.user.username);
                    navigate('/home')
                    // window.location.href = "/in";
                }
                else {
                    alert(json.message);
                    // console.log('API Response:', json);

                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                setIsLoading(false);
            });

        setIsLoading(false)

        // console.log(`Logging in with Username: ${username} and Password: ${password}`);
    };

    return (
        <div className='login-container'>
            <div className='logo'><img src={logoWhite} alt="SoSo" width={160} /></div>
            <div className='card'>
                <h2>Login</h2>
                <div className="form-item">
                    <input type="text" id="username" onChange={(e) => setUsername(e.target.value)} required />
                    <label htmlFor="username">Username</label>
                </div>

                <div className="form-item">
                    <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} required />
                    <label htmlFor="password">Password</label>
                </div>
                <button className='login-btn' onClick={handleLogin}>
                    {isLoading ? <Loader size="20px" /> : "Login"}
                </button>
                {/* <p className='register-link'>
                    Don't have an account? <Link to='/signup'>Register Now</Link>
                </p> */}
            </div>
        </div>
    );
}

export default Login