import React, { useEffect, useState } from 'react'
import './userAccounts.css'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const UserAccounts = () => {

    const userToken = localStorage.getItem('userToken')
    const userRole = localStorage.getItem('userRole')
    const userTimeZone = localStorage.getItem('userTimeZone')
    const currentUser = localStorage.getItem('currentUser')
    const userLocation = localStorage.getItem('userLocation')

    const navigate = useNavigate()

    // const BASE_URL = 'http://localhost:5000'
    const BASE_URL = 'https://dev.soso123.me'

    const [users, setUsers] = useState([])

    useEffect(() => {
        getUsers();

    }, [])


    const formatDate = (stringData) => {
        const date = new Date(stringData);

        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: "UTC"
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        return formattedDate;
    }


    const getUsers = async () => {
        try {
            const response = await fetch(`${BASE_URL}/users/getUsers`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'content-type': 'application/json'
                }
            });
            const json = await response.json();

            if (json.success) {
                const sortedData = json.data.sort((a, b) => {
                    const dateA = new Date(a.cDate);
                    const dateB = new Date(b.cDate);

                    // Compare the dates in descending order
                    return dateB - dateA;
                });
                setUsers(sortedData);
                // console.log(json.data)
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            // Handle errors appropriately (e.g., show an error message to the user)
        }
    };

    const handleUpdateAccount = (index) => {
        navigate(`/update-account/${index}`)
    }

    const handleDeleteAccount = async (index) => {

        const userToDelete = (users[index]).aName;
        try {
            const response = await fetch(`${BASE_URL}/admin/deleteUser`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ username: userToDelete })
            });
            const json = await response.json();

            alert(json.message)
            // console.log(json.message)

            getUsers();

        } catch (error) {
            console.error('Error fetching users:', error);
            // Handle errors appropriately (e.g., show an error message to the user)
        }
    }


    return (
        <div className="container">
            <h2 className='title'>User Accounts</h2>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>aName</th>
                        <th>Access</th>
                        <th>Location</th>
                        <th>TimeZone</th>
                        <th>BoxMax</th>
                        <th>StrMax</th>
                        <th>AM_Display</th>
                        <th>AM_Lock</th>
                        <th>PM_Display</th>
                        <th>PM_Lock</th>
                        <th>cDate</th>
                        <th>Active</th>
                        <th>Notes</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td onClick={() => handleUpdateAccount(index)}
                                className='aName-hyperlink'>{user.aName}</td>
                            <td>{user.isSuperAdmin ? "SUPERADMIN" : (user.isAdmin ? "ADMIN" : "AGENT")}</td>
                            <td>{user.Location}</td>
                            <td>{user.TimeZone}</td>
                            <td>{user.BoxMax}</td>
                            <td>{user.StrMax}</td>
                            <td>{user.AM_Display}</td>
                            <td>{user.AM_Lock}</td>
                            <td>{user.PM_Display}</td>
                            <td>{user.PM_Lock}</td>
                            <td>{user.cDate && formatDate(user.cDate)}</td>
                            <td>{user.Active ? "Yes" : "No"}</td>
                            <td>{user.Notes}</td>
                            <td>{user.isSuperAdmin ?
                                <></> :
                                <button className='delete-account bg-danger'
                                    onClick={() => handleDeleteAccount(index)}>Delete</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default UserAccounts