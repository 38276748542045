import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './../App.css';
import Login from './../Pages/Login';
import Signup from './../Pages/Signup';
import AppNavbar from './../Components/Navbar';
import ChangePassword from './../Pages/ChangePassword';
import UserProfile from './../Components/UserProfile';
import Home from './../Pages/Home';
import Report from './../Pages/Report';
import { useEffect, useState } from 'react';
import UserAccounts from '../Pages/UserAccounts';

const AdminRoute = ({ element }) => {
    const userRole = localStorage.getItem('userRole');
    const isAdmin = userRole && userRole.includes("ADMIN") || userRole && userRole.includes("SUPERADMIN");

    return isAdmin ? element : <Navigate to="/report" />;
};

const AppRoutes = () => {
    const [userToken, setUserToken] = useState(localStorage.getItem('userToken'));
    const userRole = localStorage.getItem('userRole');


    useEffect(() => {
        setUserToken(localStorage.getItem('userToken'));

        return () => { };
    }, [useLocation().pathname]);

    return (
        <>
            {userToken ? <AppNavbar /> : null}
            <Routes>
                {userToken ? (
                    <>
                        {userRole === 'AGENT' ?
                            <Route index element={<Navigate to="/home" />} />
                            : <Route index element={<Navigate to="/report" />} />}

                        <Route path="/signup" element={<AdminRoute element={<Signup />} />} />
                        <Route
                            path="/update-account/:id"
                            element={<AdminRoute element={<ChangePassword />} />}
                        />
                        <Route path="/user-profile" element={<UserProfile />} />
                        <Route path="/users-accounts" element={<AdminRoute element={<UserAccounts />} />} />
                        <Route path="/home" element={userRole === 'AGENT' ? <Home /> : <Report />} />
                        <Route path="/report" element={<Report />} />
                    </>
                ) : (
                    <>
                        <Route path="/" element={<Login />} />
                        <Route path="/*" element={<Navigate to="/" />} />
                    </>
                )}
            </Routes>
        </>
    );
};

export default AppRoutes;
